<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="ma-0 pa-0">
				<v-card class="transparent elevation-0">
					<v-card-title primary-title>
						<v-btn 
							class="mr-2" 
							small 
							dark 
							color="green"
							tile
							@click="dialogAceptar = true"
							v-if="getdatosUsuario.iderp != 568"
						>
							Pagar
						</v-btn>

						<v-btn 
							class="mr-2" 
							small 
							dark 
							color="green"
							tile
							@click="liberarNomina( )"
							v-if="getdatosUsuario.iderp != 568"
						>
							Liberar
						</v-btn>

						<v-spacer></v-spacer>
						<v-btn 
							class="mr-2" 
							small 
							dark 
							color="green"
							tile
							@click="exportarInscripciones()"
							v-if="getdatosUsuario.iderp != 568"
						>
							<v-icon>mdi-microsoft-excel</v-icon>
						</v-btn>

						<v-btn
							color="primary"
							dark
							@click="initialize(), getLiberaNomina( ) "
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>
					<v-card-text>

						<v-row>
							<!-- Estatus de nómina -->
							<v-col cols="12">
								<v-chip class="mr-2" dark small :color=" estatusComisiones    ? 'green' : 'red' ">Comisiones</v-chip>
								<v-chip class="mr-2" dark small :color=" estatusHorasExtra    ? 'green' : 'red' ">Horas extra</v-chip>
								<v-chip class="mr-2" dark small :color=" estatusCambioHorario ? 'green' : 'red' ">Cambio horario</v-chip>
								<v-chip              dark small :color=" estatusSinSueldo     ? 'green' : 'red' ">Sueldo</v-chip>
							</v-col>
						</v-row>

						<v-tabs
		          v-model="tab"
		          align-with-title
		        >
		          <v-tabs-slider></v-tabs-slider>

		          <v-tab>Nómina</v-tab>
		          <v-tab v-if="getdatosUsuario.iderp != 568">Comparativa</v-tab>
		          <v-tab v-if="getdatosUsuario.iderp != 568">Comp. comisiones</v-tab>
		          <v-tab v-if="getdatosUsuario.iderp != 568">Comp. Bonos RI</v-tab>
		          <v-tab v-if="getdatosUsuario.iderp != 568">Comp. Bonos NI</v-tab>
		          <v-tab v-if="getdatosUsuario.iderp != 568">Comp. Bonos Puntualidad</v-tab>
		        </v-tabs>

		        <v-tabs-items v-model="tab">

		        	<!-- Nómina administrativa -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>

				          	<v-radio-group
								      v-model="opcionFiltro"
								      row
								    >
								      <v-radio
								        label="Pendiente"
								        :value="0"
								      ></v-radio>
								      <v-radio
								        label="Aceptados"
								        :value="1"
								      ></v-radio>
								      <v-radio
								        label="Rechazados"
								        :value="2"
								      ></v-radio>
								      <v-radio
								        label="Todos"
								        :value="3"
								      ></v-radio>
								    </v-radio-group>

								    <v-row justify="space-between">

								    	<v-col cols="12" md="6">
										    <div class="black--text">
										    	Avance: {{ nominaActual.filter( el => el.estatus_nomina == 1 ).length ? ( (nominaActual.filter( el => el.estatus_nomina == 1 ).length / nominaActual.length ) *100).toFixed(2) : 0 }} %
										    </div>
								    	</v-col>

								    	<v-col cols="12" md="6">
										    <v-text-field
										      label="Buscar"
										      v-model="search"
										      dense
										      single-line
										      filled
										    ></v-text-field>
								    	</v-col>
								    </v-row>


						        <v-data-table
			            		:headers="headers"
			            		:items="filterEntradas"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>
		            			<!-- Valores que ocupan botons, chips, iconos, etc -->
			            		<template v-slot:item.nombre_completo="{ item }">
			            			<span 
			            				:class="item.estatus_nomina == 1 ? 'green--text' : item.estatus_nomina == 2 ? 'red--text' : ''"
			            				@click="verComentarios( item )"
			            				type="button"
			            			>
			            				{{ item.nombre_completo }}
			            			</span>
			            		</template>

			            		<!-- Valores que ocupan botons, chips, iconos, etc -->
			            		<template v-slot:item.tipo_pago="{ item }">
			            			<v-chip v-if="item.tipo_pago == 2" small color="green" dark >Hora</v-chip>
			            			<v-chip v-if="item.tipo_pago == 1" small color="blue" dark >Dia</v-chip>
			            			<v-chip v-if="item.tipo_pago == null || item.tipo_pago == 0" small color="orange" dark >Sin Tipo Pago</v-chip>
			            		</template>

			            		<template v-slot:item.confirmacion_comisiones="{ item }">
			            			<v-chip v-if="(item.confirmacion_comisiones == 3)" small color="green" dark >Aceptadas</v-chip>
			            			<v-chip v-if="(item.confirmacion_comisiones == 4)" small color="blue" dark >Rechazadas</v-chip>
			            			<v-chip v-if="item.puesto == 'Encargada de sucursal' && (item.confirmacion_comisiones == null || item.tipo_pago == 0)" small color="orange" dark >Pendiente</v-chip>
			            		</template>

			            		<template v-slot:item.monto_laborado="{ item }">
			            			<span type="button" @click="goPrenomina(item.nombre_completo )">{{ item.monto_laborado }}</span>
			            		</template>

			            		<template v-slot:item.monto_horas_extras="{ item }">
			            			<span type="button" @click="goHorasExtras(item.nombre_completo )">{{ item.monto_horas_extras }}</span> 
			            		</template>

			            		<template v-slot:item.comisiones_semana="{ item }">
			            			<span type="button" @click="goComisiones(item.nombre_completo )">{{ item.comisiones_semana }}</span>
			            		</template>

			            		<template v-slot:item.monto_total_rebajes="{ item }">
			            			<span type="button" @click="goRebajes(item.nombre_completo )">{{ item.monto_total_rebajes }}</span>
			            		</template>

			            		<template v-slot:item.monto_total_bonos="{ item }">
			            			<span v-if="item.puesto != 'Teacher'" @click="goBonos(item.nombre_completo )">{{ item.monto_total_bonos }}</span>
			            			<span v-if="item.puesto == 'Teacher'" >{{ item.monto_total_bonos }}</span>
			            		</template>

			            		<template v-slot:item.monto_total="{ item }">
			            			<span type="button" @click="formulaMontoTotal(item), dialogMontoTotal = true">{{ item.monto_total }}</span>
			            		</template>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

		        	<!-- Comparativa nómina-->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text 
				          	v-for="(departamento , i) in departamentos" 
				          	:key="i"
				          	class="black--text"
				          >
				        		<div class="text-h6"><b>Departamento:</b> {{ departamento.departamento }}</div>
				          	<v-data-table
			            		:headers="headersComparativa"
			            		:items="departamento.nomina"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>



				          <v-data-table
		            		:headers="headersComparativaFinal"
		            		:items="comparativaFinal"
		            		class="elevation-0"
		            		:search="search"
		            		:page.sync="page"
		            		@page-count="pageCount = $event"
		            		:mobile-breakpoint="100"
		            		dense
		            		item-key="idchecador"
	            		>

			            	<template v-slot:no-data>
			            		<v-btn
				            		color="primary"
				            		@click="initialize"
				            		small
			            		>
			            			Actualizar
				            	</v-btn>
				            </template>
				          </v-data-table>

				          <v-data-table
		            		:headers="headersComparativaFinal"
		            		:items="comparativaFinalBonos"
		            		class="elevation-0"
		            		:search="search"
		            		:page.sync="page"
		            		@page-count="pageCount = $event"
		            		:mobile-breakpoint="100"
		            		dense
		            		item-key="idchecador"
	            		>

			            	<template v-slot:no-data>
			            		<v-btn
				            		color="primary"
				            		@click="initialize"
				            		small
			            		>
			            			Actualizar
				            	</v-btn>
				            </template>
				          </v-data-table>

				          <v-data-table
		            		:headers="headersComparativaFinal"
		            		:items="comparativaFinalFinal"
		            		class="elevation-0"
		            		:search="search"
		            		:page.sync="page"
		            		@page-count="pageCount = $event"
		            		:mobile-breakpoint="100"
		            		dense
		            		item-key="idchecador"
	            		>

			            	<template v-slot:no-data>
			            		<v-btn
				            		color="primary"
				            		@click="initialize"
				            		small
			            		>
			            			Actualizar
				            	</v-btn>
				            </template>
				          </v-data-table>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de comisiones -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-data-table
			            		:headers="headersComparativaComisiones"
			            		:items="comparativaComisiones"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de bono RI -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-data-table
			            		:headers="headerscomparativaRI"
			            		:items="bonosRi"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de bono NI -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-data-table
			            		:headers="headerscomparativaNI"
			            		:items="bonosNI"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de bono Puntualidad -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-data-table
			            		:headers="headerscomparativaPuntualidad"
			            		:items="bonosPu"
			            		class="elevation-0"
			            		:search="search"
			            		:page.sync="page"
			            		@page-count="pageCount = $event"
			            		:mobile-breakpoint="100"
			            		dense
			            		item-key="idchecador"
		            		>

				            	<template v-slot:no-data>
				            		<v-btn
					            		color="primary"
					            		@click="initialize"
					            		small
				            		>
				            			Actualizar
					            	</v-btn>
					            </template>
					          </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				    </v-tabs-items>
			    </v-card-text>
				</v-card> 
			</v-col>

			<v-dialog v-model="dialogAceptar" max-width="500px">
				<v-card>
					<v-card-text>
							<v-row>
							<v-col cols="6">
								<v-card class="elevation-0" height="100%" style="display: table">
									<div
									class="text-h6"
									style="display: table-cell; vertical-align: middle"
									>
									<strong>Aceptar:</strong> ¿Estás seguro que deseas guardar la nómina?
									<v-card-actions class="pt-6">
										<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
										<v-btn
										color="error"
										text
										large
										class="mr-2"
										@click="dialogAceptar=false"
										>No, cancelar</v-btn
										>
										<!-- Guardar la información  -->
										<v-btn
										color="#5C5C5C"
										dark
										class="elevation-6"
										large
										@click="dialogAceptar = false, save()"
										>Si</v-btn
										>
									</v-card-actions>
								</div>
								</v-card>
							</v-col>
							<v-col cols="6">
								<v-img src="@/assets/contrato.jpg"></v-img>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogActualizar" max-width="500px">
				<v-card>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-card class="elevation-0" height="100%" style="display: table">
									<div
										class="text-h6"
										style="display: table-cell; vertical-align: middle"
									>
										<strong>Advertencia:</strong> ¿Ya existe una nómina guardada de esta semana, deseas sobrescribirla?
										<v-card-actions class="pt-6">
											<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
											<v-btn
												color="error"
												text
												large
												class="mr-2"
												@click="dialogActualizar=false"
											>No, cancelar</v-btn>
											<!-- Guardar la información  -->
											<v-btn
												color="#5C5C5C"
												dark
												class="elevation-6"
												large
												@click="dialogActualizar = false, updateNominaSemanal()"
											>Si</v-btn>
										</v-card-actions>
									</div>
								</v-card>
							</v-col>
							<v-col cols="6">
								<v-img src="@/assets/advertencia_nomina.gif"></v-img>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>

		<v-dialog
		  v-model="dialogComentarios"
		  max-width="500px"
		  v-if="renglonNomina"
		>
			<v-card>
				<v-card-title primary-title>
				  Comentarios de nómina
				</v-card-title>

			  <v-card-text>
			  	<v-row>
			  		<v-col cols="12"
			  			v-for="(mensaje, i) in renglonNomina.comentarios" :key="i"
			  		>
					  	<p 
					  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer' }`">
					    	{{ mensaje.comentarios }}
					    	<br/>
					    	{{ mensaje.nombre_completo }}
					  	</p>
			  		</v-col>
			  	</v-row>
			    <br/>
			  </v-card-text>

			  <v-card-text>
			    <v-textarea
			  		v-model="respuesta"
			  		filled
			  		:rows="3"
			  		auto-grow
			  		label="respuesta al colaborador"
			  		hide-details
			  	></v-textarea>
			  </v-card-text>
			  
			  <v-card-actions>
			  	<v-btn 
			  		color="black"
			  		@click="dialogComentarios = false"
			  		tile
			  		dark
			  		small
			  	>
			  		<v-icon left span>mdi-cancel</v-icon>
			  		Cancelar
			  	</v-btn>
			    <v-spacer></v-spacer>
			  	<v-btn 
			  		color="success"
			  		@click="responder( )"
			  		tile
			  		small
			  	>
			  		<v-icon left span>mdi-content-save</v-icon>
			  		responder
			  	</v-btn>
			  </v-card-actions>
			</v-card>	
		</v-dialog>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';


	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'
	import liberaNomina    from '@/mixins/liberaNomina'


	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel, liberaNomina ],

		props:[
			'selectedHeaders',
			'ciclo',
			'fecha'
		],


		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			dialogAceptar: false,
			dialogActualizar: false,
			ciclos:[],
			gruposRoles: [],
			pagos: [],
			id_ciclo: 0,

      // tabla
			search:'',
			search2:'',
			formula_monto_laborado:'',
			formula_horas_extras:'',
			formula_monto_rebajes:'',
			formula_monto_bonos:'',
			formula_monto_total:'',
			page: 1,
			page2: 1,
			pageCount: 0,
			itemsPerPage: 10,
			nominaActual: [],
			nomina_pasada: [],
			nominaSemanal: [],
			autorizarNomina: [],
			nominaEstatus: [],
			asistencias: [],
			estatus: null,
			entradassalidasteachers: [],
			horasDobles:[],
			horasTotales: 0,

			headers: [
				{ text: 'Empleado'              , value: 'nombre_completo'      },
				{ text: 'Puesto'                , value: 'puesto'               },
				{ text: 'Plantel'               , value: 'plantel'              },
				{ text: 'Monto Jornada Laboral' , value: 'monto_laborado'       , align: 'end'},
				{ text: 'Monto Horas Extras'    , value: 'monto_horas_extras'   , align: 'end'},
				{ text: 'Monto Comisiones'      , value: 'comisiones_semana'    , align: 'end'},
				{ text: 'Monto Rebajes'         , value: 'monto_total_rebajes'  , align: 'end'},
				{ text: 'Monto Bonos'           , value: 'monto_total_bonos'    , align: 'end'},
				{ text: 'Monto Total'           , value: 'monto_total'          , align: 'end'},
			],

			headersComparativa: [
				{ text: 'Empleado'     , value: 'nombre_completo'      },
				{ text: 'Puesto'       , value: 'puesto'               },
				{ text: 'Plantel'      , value: 'plantel'              },
				{ text: 'Monto Ant'    , value: 'monto_total_ant'      , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act'      , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia'           , align: 'end' },
				{ text: 'Motivo'       , value: 'motivo'               },
			],

			headersComparativaFinal: [
				{ text: 'Empleado'     , value: 'Concepto'                            },
				{ text: 'Monto Ant'    , value: 'monto_total_ant'      , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act'      , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia'           , align: 'end' },
			],

			headersComparativaComisiones: [
				{ text: 'Empleado'     , value: 'nombre_completo'                                },
				{ text: 'Puesto'       , value: 'puesto'                                         },
				{ text: 'Plantel'      , value: 'plantel'                                        },
				{ text: 'Monto Ant'    , value: 'monto_total_ant_comisiones'      , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act_comisiones'      , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia_comisiones'           , align: 'end' },
			],

			headerscomparativaRI: [
				{ text: 'Empleado'     , value: 'nombre_completo'                   },
				{ text: 'Puesto'       , value: 'puesto'                            },
				{ text: 'Plantel'      , value: 'plantel'                           },
				{ text: 'Monto Ant'    , value: 'bono_ri'            , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act'    , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia'         , align: 'end' },
			],

			headerscomparativaNI: [
				{ text: 'Empleado'     , value: 'nombre_completo'                   },
				{ text: 'Puesto'       , value: 'puesto'                            },
				{ text: 'Plantel'      , value: 'plantel'                           },
				{ text: 'Monto Ant'    , value: 'bono_ri'            , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act'    , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia'         , align: 'end' },
			],

			headerscomparativaPuntualidad: [
				{ text: 'Empleado'     , value: 'nombre_completo'                   },
				{ text: 'Puesto'       , value: 'puesto'                            },
				{ text: 'Plantel'      , value: 'plantel'                           },
				{ text: 'Monto Ant'    , value: 'puntualidad'        , align: 'end' },
				{ text: 'Monto Act'    , value: 'monto_total_act'    , align: 'end' },
				{ text: 'Difencia'     , value: 'diferencia'         , align: 'end' },
			],
			
			tab: null,
			dataNominaAnterior: [],

			renglonNomina: null,
			dialogComentarios: false,
			respuesta: '',
			opcionFiltro: 3,
			departamentos:[],

			comparativaComisiones:[],
			bonosRi: [],
			bonosNI: [],
			bonosPu: [],

			totalAnterior: 0,
			totalActual:0,
			diferenciaTotal: 0,

			comparativaFinal:[],
			comparativaFinalBonos:[],
			comparativaFinalFinal:[],

			estatusComisiones: false,
			estatusHorasExtra: false,
			estatusCambioHorario: false,
			estatusSinSueldo: false,
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			filterEntradas( ){
				let entradass = this.nominaActual
				if( this.opcionFiltro != 3 ){

					entradass = entradass.filter( el => el.estatus_nomina == this.opcionFiltro )

				} 
				return entradass.filter(el =>  el.puesto !== 'Sin puesto');
			},

			filterEntradasEncargadas( ){
				let entradass = this.nominaActual;



				return entradass.filter(el => el.puesto !== 'Sin puesto' && el.puesto == 'Encargada de sucursal');
			},

		},

		async created () {
			await this.getDepartamentos( )
			await this.initialize()
			await this.getLiberaNomina( )

		},

		methods: {

			initialize () {
				this.cargar = true
				this.nominaActual = []
				this.nominaActualBonos = []

				const payload = {
					fecha   : this.fecha,   
					id_ciclo: this.ciclo.id_ciclo,
					semana  : this.selectedHeaders 
				}

				return this.$http.post('usuarios.entradas', payload).then(async response=>{

					for( const i in response.data.result3 ){

						const { bonos_ventas, bonos_ri, bonos_puntualidad, monto_total, monto_total_bonos, compensaciones } = response.data.result3[i]

						let bono1           = bonos_ventas ? bonos_ventas.replace('$','').replace(',','')  : 0
						let bono2           = bonos_ri ? bonos_ri.replace('$','').replace(',','')  : 0
						let bono3           = bonos_puntualidad ? bonos_puntualidad.replace('$','').replace(',','') : 0
						let bono4           = monto_total_bonos ? monto_total_bonos.replace('$','').replace(',','') : 0
						let total           = monto_total ? monto_total.replace('$','').replace(',','') : 0
						let compensaciones2 = compensaciones ? compensaciones.replace('$','').replace(',','') : 0

						response.data.result3[i].monto_total_bonos = this.format( parseFloat( compensaciones2 ) + parseFloat( bono1 ) + parseFloat( bono2 ) + parseFloat( bono3 ) )
						// response.data.result3[i].monto_total       = this.format(total)

					}

					if( this.getdatosUsuario.iderp == 568 ){
						this.nominaActual      = response.data.result3.filter( el => el.departamento == 'Operaciones');
					}else{
						this.nominaActual      = response.data.result3;
					}


					console.log( this.nominaActual[0] )

					this.nominaActualBonos = response.data.result3;
					await this.nominaAnterior()
					this.compararSemanas( )

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},


			getDepartamentos( ){

				this.departamentos = []

				this.$http.get('departamentos.all').then(response=>{
        
          this.departamentos = response.data.filter( el => el.iddepartamento != 9) 
          
        }).catch( error =>{
					this.validarErrorDirecto( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			nominaAnterior () {
				this.cargar = true
				this.dataNominaAnterior = []

				const payload = {
					fecha   : this.fecha,   
					id_ciclo: 342, // this.ciclo.id_ciclo,
					semana  : 5,// this.selectedHeaders == 1 ? 4 : this.selectedHeaders - 1, // this.selectedHeaders,

					id_ciclo_ant: 0,
					semana_bonos: 0// this.selectedHeaders == 2 ? 2 : 0,
				}

				return this.$http.post('usuarios.entradas.anterior', payload).then(response=>{

					this.dataNominaAnterior = response.data.nominaAnterior
					this.bonosRi            = response.data.bonosRi
					this.bonosNI            = response.data.bonosNI
					this.bonosPu            = response.data.bonosPu
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			compararSemanas( ){

				try{

					this.comparativaFinal = []
					this.comparativaFinalBonos = []
					this.comparativaFinalFinal = []
					// Semana actual
					// this.nominaActual
					
					// Semana anterior 
					// this.dataNominaAnterior
					// Sacar la comparativa del monto anterior vs el nuevo monto
					for( const i in this.dataNominaAnterior ){

						const { monto_laborado, monto_horas_extras, comisiones_semana, monto_total_rebajes, monto_total_bonos, monto_total, id, idsueldo_recepcion } = this.dataNominaAnterior[i]

						let monto_total_ant = monto_laborado + monto_horas_extras - monto_total_rebajes 

						this.dataNominaAnterior[i]['monto_total_ant'] = this.format( monto_total_ant )

						// Ver si existe un monto actual de esa persona por medio del id 

						const existeNominaEmpleado = this.nominaActual.find( el => el.id == id )

						
						let monto_laborado2      = existeNominaEmpleado && existeNominaEmpleado.monto_laborado      ? existeNominaEmpleado.monto_laborado.replace('$','').replace(',','')      : 0
						let monto_horas_extras2  = existeNominaEmpleado && existeNominaEmpleado.monto_horas_extras  ? existeNominaEmpleado.monto_horas_extras.replace('$','').replace(',','')  : 0  
						let comisiones_semana2   = existeNominaEmpleado && existeNominaEmpleado.comisiones_semana   ? existeNominaEmpleado.comisiones_semana.replace('$','').replace(',','')   : 0  
						let monto_total_rebajes2 = existeNominaEmpleado && existeNominaEmpleado.monto_total_rebajes ? existeNominaEmpleado.monto_total_rebajes.replace('$','').replace(',','') : 0  
						let monto_total_bonos2   = existeNominaEmpleado && existeNominaEmpleado.monto_total_bonos   ? existeNominaEmpleado.monto_total_bonos.replace('$','').replace(',','')   : 0  
						let monto_total2         = existeNominaEmpleado && existeNominaEmpleado.monto_total         ? existeNominaEmpleado.monto_total.replace('$','').replace(',','')         : 0 
						let idsueldo_recepcion2  = existeNominaEmpleado && existeNominaEmpleado.idsueldo_recepcion  ? existeNominaEmpleado.idsueldo_recepcion                                  : 0


						let monto_total_act = parseFloat(monto_laborado2) + parseFloat(monto_horas_extras2) - parseFloat(monto_total_rebajes2) 

						this.dataNominaAnterior[i]['monto_total_act'] = this.format( monto_total_act )

						this.dataNominaAnterior[i]['diferencia'] = this.format( monto_total_act - monto_total_ant )

						// Agregar los motivos

						let motivo = ''

						if( monto_laborado != monto_laborado2 ){
							motivo += ' Diferencia en el total de días laborados'
						}

						if( monto_horas_extras != monto_horas_extras2 ){
							motivo += ' , Diferencia en horas extra'
						}

						if( monto_total_rebajes != monto_total_rebajes2 ){
							motivo += ' , Diferencia en los rebajes'
						}

						if( monto_total_bonos != monto_total_bonos2 ){
							motivo += ' , Diferencia en los bonos'
						}


						if( idsueldo_recepcion != idsueldo_recepcion2 ){
							motivo += ' , Diferencia en sueldo'
						}

						this.dataNominaAnterior[i]['motivo'] = motivo
					}

					// Sacamos los nuevos
					let trabajadoresAnteriores = this.dataNominaAnterior.map((registro) => { return registro.id })

					let nuevosEmpleados = this.nominaActual.filter( el => !trabajadoresAnteriores.includes( el.id ) )

					// Ahora separamos todo por departamento
					for( const i in this.departamentos ){

						const { iddepartamento } = this.departamentos[i]

						this.departamentos[i]['nomina'] = this.dataNominaAnterior.filter( el => el.iddepartamento == iddepartamento)

						let nuevosEmpleadosPuesto = nuevosEmpleados.filter( el => el.iddepartamento == iddepartamento)


						for(const j in nuevosEmpleadosPuesto ){
							
							let monto_final = parseFloat( nuevosEmpleadosPuesto[j].monto_total.replace('$','').replace(',','') )
							let monoBono    = parseFloat( nuevosEmpleadosPuesto[j].monto_total_bonos  ) ? parseFloat( nuevosEmpleadosPuesto[j].monto_total_bonos  ) : 0
							
							// Agregar a los nuevos
							this.departamentos[i].nomina.push({
								nombre_completo: nuevosEmpleadosPuesto[j].nombre_completo,
								puesto         : nuevosEmpleadosPuesto[j].puesto,
								plantel        : nuevosEmpleadosPuesto[j].plantel,
								monto_total_ant: this.format( 0 ),
								monto_total_act: this.format( monto_final - monoBono ),
								diferencia     : this.format( monto_final - monoBono ),
								motivo         : 'ALTA'
							})
						}
					}

					let totalAnterior2  = 0
					let totalActual2 = 0
					let diferenciaTotal2 = 0

					// Sacar los totales
					let nominaActualLideres = 0
					let nominaAnteriorLideres = 0

					// Sacar los totales solo para teacher Líder
					for( const i in this.departamentos ){

						const { nomina, departamentos } = this.departamentos[i]

						let totalAnterior = nomina.filter( el => el.puesto == 'Teacher Líder' ).map(item => parseFloat( item.monto_total_ant.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)
						
						nominaAnteriorLideres += totalAnterior

						let totalActual   = nomina.filter( el => el.puesto == 'Teacher Líder' ).map(item => parseFloat( item.monto_total_act.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)

						nominaActualLideres += totalActual

						if( totalAnterior || totalActual ){
							this.departamentos[i].nomina.push({
								nombre_completo: 'Nomina Teacher Líder' ,
								puesto         : '',
								plantel        : '',
								monto_total_ant: this.format( totalAnterior ),
								monto_total_act: this.format( totalActual ),
								diferencia     : this.format( totalActual - totalAnterior ),
								motivo         : ''
							})
						}
					}

					for( const i in this.departamentos ){

						const { nomina, departamento } = this.departamentos[i]

						let totalAnterior3 = nomina.filter( el => el.puesto != 'Teacher Líder' && el.puesto ).map(item => parseFloat( item.monto_total_ant.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)

						totalAnterior2 += totalAnterior3

						let totalActual3   = nomina.filter( el => el.puesto != 'Teacher Líder' && el.puesto ).map(item => parseFloat( item.monto_total_act.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)

						totalActual2 += totalActual3

						diferenciaTotal2 += totalActual3 - totalAnterior3

						this.departamentos[i].nomina.push({
							nombre_completo: departamento == 'Operaciones' ? 'Nomina Operación ( Recep, Enc, Vent, Limp, Mto, Aux., Prog Product, coord. )' : `Nomina ${departamento}` ,
							puesto         : '',
							plantel        : '',
							monto_total_ant: this.format( totalAnterior3 ),
							monto_total_act: this.format( totalActual3 ),
							diferencia     : this.format( totalActual3 - totalAnterior3 ),
							motivo         : ''
						})

						this.comparativaFinal.push({
							Concepto       : departamento == 'Operaciones' ? 'Nomina Operación ( Recep, Enc, Vent, Limp, Mto, Aux., Prog Product, coord., Teach )' : `Nomina ${departamento}` ,
							monto_total_ant: departamento == 'Operaciones' ? this.format( totalAnterior3 + nominaAnteriorLideres ) : this.format( totalAnterior3  ),
							monto_total_act: departamento == 'Operaciones' ? this.format( totalActual3 + nominaActualLideres ) : this.format( totalActual3  ),
							diferencia     : departamento == 'Operaciones' ? this.format( totalActual3 - totalAnterior3 + nominaActualLideres - nominaAnteriorLideres )	 : 	this.format( totalActual3 - totalAnterior3 )	
						})

					}



					// Sacar los totales solo para teacher Líder
					for( const i in this.departamentos ){

						const { nomina, departamento } = this.departamentos[i]

						if( departamento == "Operaciones" ){

							let nominas = nomina.filter( el => el.nombre_completo.match('Nomina') )

							let nomninaActual     = nominas.map(item => parseFloat( item.monto_total_act.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)
							let nomninaAnterios   = nominas.map(item => parseFloat( item.monto_total_ant.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)
							let nomninaDiferencia = nominas.map(item => parseFloat( item.diferencia.replace(',','').replace('$','') ) ).reduce((prev, curr) => prev + curr, 0)

							this.departamentos[i].nomina.push({
								nombre_completo: 'Nomina Total Operativa' ,
								puesto         : '',
								plantel        : '',
								monto_total_ant: this.format( nomninaAnterios ),
								monto_total_act: this.format( nomninaActual ),
								diferencia     : this.format( nomninaActual - nomninaAnterios ),
								motivo         : ''
							})
						}
					}


					this.totalAnterior    = this.format( totalAnterior2 )
 					this.totalActual      = this.format( totalActual2 )
					this.diferenciaTotal	= this.format( totalActual2 - totalAnterior2 )			

					

					// COMPARAR LAS COMISIONESSSS
					// monto_total_ant_comisiones
					// monto_total_act_comisiones
					// diferencia_comisiones

					let comsionesAnt = 0
					let comicionesAct = 0
					let comisionesDif = 0

					this.comparativaComisiones = this.dataNominaAnterior.filter( el => el.comisiones_semana )
					// Sacamos los nuevos
					let comsionesAnteriores = this.comparativaComisiones.map((registro) => { return registro.id })

					let nuevasComisiones    = this.nominaActual.filter( el => !comsionesAnteriores.includes( el.id ) && el.comisiones_semana )

					for( const i in this.comparativaComisiones ){

						const { comisiones_semana, id } = this.comparativaComisiones[i]

						const existenComisiones =  this.nominaActual.find( el => el.id == id )

						let monto_total_act_comisiones = existenComisiones && existenComisiones.comisiones_semana ? existenComisiones.comisiones_semana : '$0.00'

						let diferencia_comisiones =  parseFloat(monto_total_act_comisiones.replace(',','').replace('$','') ) - comisiones_semana

						comsionesAnt  += comisiones_semana
						comicionesAct += parseFloat(monto_total_act_comisiones.replace(',','').replace('$','') )
						comisionesDif += diferencia_comisiones

						this.comparativaComisiones[i]['monto_total_ant_comisiones'] = this.format( comisiones_semana )

						this.comparativaComisiones[i]['diferencia_comisiones']      = this.format( diferencia_comisiones )

						this.comparativaComisiones[i]['monto_total_act_comisiones'] = this.format( monto_total_act_comisiones )
					}

					for( const i in nuevasComisiones ){

						const { comisiones_semana, id, nombre_completo } = nuevasComisiones[i]

						let comisionesActNuevas = parseFloat(comisiones_semana.replace(',','').replace('$','') )

						comicionesAct += comisionesActNuevas

						this.comparativaComisiones.push({
							nombre_completo,
							puesto: 'ENCARGADA DE SUCURSAL',
							plantel:'SIN PLANTEL',
							monto_total_ant_comisiones: '$0.00',
							diferencia_comisiones     : this.format( comisionesActNuevas ),
							monto_total_act_comisiones: this.format( comisionesActNuevas )
						})

					}

					this.comparativaComisiones.push({
						nombre_completo            : 'TOTAL',
						puesto                     : '',
						plantel                    : 'TODOS',
						monto_total_ant_comisiones : this.format( comsionesAnt ),
						monto_total_act_comisiones : this.format( comicionesAct ),
						diferencia_comisiones      : this.format( comicionesAct - comsionesAnt )
					})

					this.comparativaFinal.push({
						Concepto       : 'TOTAL DE NOMINA',
						monto_total_ant: this.format( totalAnterior2 + nominaAnteriorLideres ),
						monto_total_act: this.format( totalActual2  + nominaActualLideres),
						diferencia     : this.format( (totalActual2 ) - ( totalAnterior2 ) + ( nominaActualLideres - nominaAnteriorLideres ) )			
					})

					let bonosRI = this.nominaActualBonos.filter( el => el.bonos_ri ) 

					// Sacamos los nuevos
					let bonoRIAnte     = this.bonosRi.map((registro) => { return registro.id })

					let nuevosBonosRI  = bonosRI.filter( el => !bonoRIAnte.includes( el.id ) )


					// For para los bonos de RI 
					// bonosRi

					let bonoRIAnt = 0
					let bonoRIAct = 0
					let bonoRIDif = 0

					for( const i in this.bonosRi ){

						let { bono_ri, id } = this.bonosRi[i]

						let existeBonoRi = bonosRI.find( el => el.id == id ) 

						let monto_total_act = existeBonoRi ? existeBonoRi.bonos_ri : 0

						let diferencia = bono_ri - monto_total_act

						bonoRIAct += parseFloat( monto_total_act )
						bonoRIAnt += parseFloat( bono_ri )
						bonoRIDif += parseFloat( diferencia )

						this.bonosRi[i]['monto_total_act'] = this.format( monto_total_act )
						
						this.bonosRi[i]['diferencia'] = this.format( diferencia )

						this.bonosRi[i].bono_ri = this.format( bono_ri )

					}

					// Agregamos los nuevos BONOSSSS
					for( const i in nuevosBonosRI ){

						const { nombre_completo, id, puesto, plantel, bonos_ri } = nuevosBonosRI[i]

						bonoRIAnt += 0
						bonoRIAct += parseFloat( bonos_ri )
						bonoRIDif += parseFloat( bonos_ri )

						this.bonosRi.push({
							nombre_completo,
							puesto,
							plantel,
							bono_ri: 0,
							monto_total_act: this.format( bonos_ri ),
							diferencia: this.format( bonos_ri )
						})

					}


					let totalRI = this.bonosRi.map(item => parseFloat( item.monto_total_act.replace(',','').replace('$','') )).reduce((prev, curr) => prev + curr, 0)
					// let totalRIAnt = this.bonosRi.map(item => parseFloat( item.bono_ri.replace(',','').replace('$','') )).reduce((prev, curr) => prev + curr, 0)

					this.bonosRi.push({
						nombre_completo    : 'TOTAL',
						puesto             : '',
						plantel            : 'TODOS',
						bono_ri            : this.format( bonoRIAnt ),
						monto_total_act    : this.format( totalRI ),
						diferencia         : this.format( totalRI - bonoRIAnt )
					})

					this.comparativaFinalBonos.push({
						Concepto       : 'Bonos RI',
						monto_total_ant: this.format( bonoRIAnt ),
						monto_total_act: this.format( totalRI ),
						diferencia     : this.format( totalRI - bonoRIAnt )			
					})


					let bonoNI = this.nominaActualBonos.filter( el => el.bonos_ventas ) 

					// Sacamos los nuevos
					let bonoNIAnte     = this.bonosNI.map((registro) => { return registro.id })

					let nuevosBonosNI  = bonoNI.filter( el => !bonoNIAnte.includes( el.id ) )

					let bonoNIAnt = 0
					let bonoNIAct = 0
					let bonoNIDif = 0

					// bonosNI
					for( const i in this.bonosNI ){

						let { bono_ni, id } = this.bonosNI[i]

						let existeBonoNi = bonoNI.find( el => el.id == id ) 

						let monto_total_act = existeBonoNi ? parseFloat(existeBonoNi.bonos_ventas.replace('$','').replace(',','')) : 0

						let diferencia = bono_ni - monto_total_act

						bonoNIAnt+= parseFloat( bono_ni )
						bonoNIAct+= parseFloat( monto_total_act )
						bonoNIDif+= parseFloat( diferencia )

						this.bonosNI[i]['monto_total_act'] = this.format( monto_total_act )
						
						this.bonosNI[i]['diferencia'] = this.format( diferencia )

						this.bonosNI[i].bono_ni = this.format( bono_ni )

					}

					// Agregamos los nuevos BONOSSSS
					for( const i in nuevosBonosNI ){

						const { nombre_completo, id, puesto, plantel, bonos_ventas } = nuevosBonosNI[i]

						console.log( bonos_ventas )

						bonoNIAnt+= 0
						bonoNIAct+= parseFloat(bonos_ventas.replace('$','').replace(',',''))
						bonoNIDif+= parseFloat(bonos_ventas.replace('$','').replace(',',''))

						this.bonosNI.push({
							nombre_completo,
							puesto,
							plantel,
							bono_ni: this.format( 0 ),
							monto_total_act: this.format( bonos_ventas ),
							diferencia: this.format( bonos_ventas )
						})

					}

					this.bonosNI.push({
						nombre_completo  : 'TOTAL',
						puesto           : '',
						plantel          : 'TODOS',
						bono_ni          : this.format( bonoNIAnt ),
						monto_total_act  : this.format( bonoNIAct ),
						diferencia       : this.format( bonoNIAct - bonoNIAnt )
					})


					/***************************************************/
					/*             BONOS DE PUNTUALIDAD                */

					let bonoPuActual = this.nominaActualBonos.filter( el => el.bonos_puntualidad ) 

					// Sacamos los nuevos
					let bonoPuAnte     = this.bonosPu.map((registro) => { return registro.id })

					let nuevosBonosPu  = bonoPuActual.filter( el => !bonoPuAnte.includes( el.id ) )

					let bonoPuAnt = 0
					let bonoPuAct = 0
					let bonoPuDif = 0

					// bonosPu Anterioressssss
					for( const i in this.bonosPu ){

						let { puntualidad, id } = this.bonosPu[i]

						let existeBonoPu = bonoPuActual.find( el => el.id == id ) 

						let monto_total_act = existeBonoPu ? parseFloat(existeBonoPu.bonos_puntualidad) : 0

						let diferencia = monto_total_act - puntualidad 

						bonoPuAnt+= parseFloat( puntualidad )
						bonoPuAct+= parseFloat( monto_total_act )
						bonoPuDif+= parseFloat( diferencia )

						this.bonosPu[i]['monto_total_act']  = this.format( monto_total_act )
						
						this.bonosPu[i]['diferencia']       = this.format( diferencia )

						this.bonosPu[i].puntualidad = this.format( puntualidad )

					}

					// Agregamos los nuevos BONOSSSS
					for( const i in nuevosBonosPu ){

						const { nombre_completo, id, puesto, plantel, bonos_puntualidad } = nuevosBonosPu[i]

						bonoPuAnt+= 0
						bonoPuAct+= parseFloat(bonos_puntualidad.replace('$','').replace(',',''))
						bonoPuDif+= parseFloat(bonos_puntualidad.replace('$','').replace(',',''))

						this.bonosPu.push({
							nombre_completo,
							puesto,
							plantel,
							puntualidad: this.format( 0 ),
							monto_total_act: this.format( bonos_puntualidad ),
							diferencia: this.format( bonos_puntualidad )
						})

					}


					this.bonosPu.push({
						nombre_completo  : 'TOTAL',
						puesto           : '',
						plantel          : 'TODOS',
						puntualidad      : this.format( bonoPuAnt ),
						monto_total_act  : this.format( bonoPuAct ),
						diferencia       : this.format( bonoPuAct - bonoPuAnt )
					})

					/******************************************************/


					this.comparativaFinalBonos.push({
						Concepto       : 'Comisiones',
						monto_total_ant: this.format( comsionesAnt ),
						monto_total_act: this.format( comicionesAct ),
						diferencia     : this.format( comicionesAct - comsionesAnt )			
					})

					this.comparativaFinalBonos.push({
						Concepto       : 'Bonos NI',
						monto_total_ant: this.format( bonoNIAnt ),
						monto_total_act: this.format( bonoNIAct ),
						diferencia     : this.format( bonoNIAct - bonoNIAnt )			
					})

					this.comparativaFinalBonos.push({
						Concepto       : 'Bonos Puntualidad',
						monto_total_ant: this.format( bonoPuAnt ),
						monto_total_act: this.format( bonoPuAct ),
						diferencia     : this.format( bonoPuAct - bonoPuAnt )			
					})

					let compensacionesAct = 0
					let compensacionesAnt = 0

					this.comparativaFinalBonos.push({
						Concepto       : 'Compensaciones',
						monto_total_ant: this.format( compensacionesAnt ),
						monto_total_act: this.format( compensacionesAct ),
						diferencia     : this.format( compensacionesAct - compensacionesAnt )			
					})

					this.comparativaFinalBonos.push({
						Concepto       : 'TOTAL BONOS Y COMISIONES',
						monto_total_ant: this.format( bonoRIAnt + bonoNIAnt + bonoPuAnt + comsionesAnt + compensacionesAnt ),
						monto_total_act: this.format( totalRI + bonoNIAct + bonoPuAct + comicionesAct + compensacionesAct ),
						diferencia     : this.format( totalRI - bonoRIAnt + bonoNIAct - bonoNIAnt + bonoPuAct - bonoPuAnt + comicionesAct - comsionesAnt  + compensacionesAct - compensacionesAnt )			

					})

					this.comparativaFinalFinal.push({
						Concepto       : 'TOTAL A PAGAR',
						monto_total_ant: this.format( totalAnterior2 + nominaAnteriorLideres + bonoRIAnt + bonoNIAnt + bonoPuAnt + comsionesAnt + compensacionesAnt),
						monto_total_act: this.format( totalActual2  + nominaActualLideres + totalRI + bonoNIAct + bonoPuAct + comicionesAct + compensacionesAct ),
						diferencia     : this.format( (totalActual2 ) - ( totalAnterior2 ) + ( nominaActualLideres - nominaAnteriorLideres ) + ( totalRI - bonoRIAnt + bonoNIAct - bonoNIAnt + bonoPuAct - bonoPuAnt + comicionesAct - comsionesAnt )  + ( compensacionesAct - compensacionesAnt ))			

					})


					// this.nominaActual

				}catch( error ){
					this.validarErrorDirecto( error.message ) 
				}

			},

			getNominaSemanal () {
				this.cargar = true

				const payload = {
					fecha:    this.fecha,   
				}

				return this.$http.post('encargadas_comisiones.get.nominasemanal', payload).then(response=>{
					this.nominaSemanal = response.data;
					this.cargar      = false  
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			save () {
				this.cargar = true
				const payload = {
					fecha   : this.fecha,  
					data    : this.filterEntradas,
					id_ciclo: this.ciclo,
					semana  : this.selectedHeaders
				}

				this.$http.post('encargadas_comisiones.add.historialnomina', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.dialogAceptar = false
					// this.getNominaSemanal()
					this.getAutorizarNomina()
					this.initialize();
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			updateNominaSemanal () {
				this.cargar = true
				const payload = {
					fecha: this.fecha,  
					data: this.filterEntradas
				}

				this.$http.post('encargadas_comisiones.update.nominasemanal', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.dialogActualizar = false
					this.getNominaSemanal()
					this.initialize();
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			verComentarios( value ){

				this.renglonNomina = value

				this.dialogComentarios = true
			},

			getAutorizarNomina() {
				this.autorizarNomina = [];
				this.overlay = true;

				const payload = {
					fecha: this.fecha
				}

				this.$http
				.post("encargadas_comisiones.get.autorizarnomina", payload)
				.then((response) => {
					this.autorizarNomina = response.data.respuesta;
					this.overlay = false;
				})
				.catch((error) => {
					// INdicamos que hay un error en el back
					if (error.body.message) {
						this.error = error.body.message;
					} else {
						this.error = error.body;
					}
					this.errorBack = true;
					this.overlay = false;
					this.dialogError = true;
				});
			},


			s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
					return buf;
			},

			exportar(){
				let data = XLSX.utils.json_to_sheet(this.nominaActual)

				const workbook = XLSX.utils.book_new()
				const filename = 'entradas_salidas'
				XLSX.utils.book_append_sheet(workbook, data, filename)

				var wbout = XLSX.write(workbook, {
					bookType: 'xls',
					bookSST: false,
					type: 'binary'
				});

				const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')

				let formData = new FormData();
		        // //se crea el objeto y se le agrega como un apendice el archivo 
				formData.append('file',file);
		        // /*getDatosRiesgo the form data*/

				this.$http.post('pdfs',formData).then(response=> {
					window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
				}).catch(error=> {
					console.log(error);
				});
			},

			formulaMontoLaborado(item) {
				let sueldo_dia = item.sueldo_dia;
				let dia_laboral = item.dia_laboral;
				this.formula_monto_laborado = `$${sueldo_dia}  X  ${dia_laboral} dias`;
			},   

			formulaMontoHorasExtras(item) {
				let sueldo_hora = item.sueldo_hora;
				let cantidad_horas = item.cantidad_horas;
				this.formula_horas_extras = `${sueldo_hora}  X  ${cantidad_horas} horas extras`;
			},  

			formulaMontoRebajes(item) {
				let rebajes = item.rebajes !== undefined && item.rebajes !== null ? item.rebajes : 0.00;
				let seguroIMSS = item.seguroIMSS !== undefined && item.seguroIMSS !== null ? item.seguroIMSS : 0.00;
				let cajaAhorro = item.cajaAhorro !== undefined && item.cajaAhorro !== null ? item.cajaAhorro : 0.00;
				let monto_retardo = item.monto_retardo !== undefined && item.monto_retardo !== null ? item.monto_retardo : 0.00;
				let retensiones = item.retensiones !== undefined && item.retensiones !== null ? item.retensiones : 0.00;
				let devoluciones = item.devoluciones !== undefined && item.devoluciones !== null ? item.devoluciones : 0.00;

				this.formula_monto_rebajes = `
				Monto Retardo: $${monto_retardo}\n  
				Monto Otros: $${rebajes}\n  
				Monto Seguro IMSS: $${seguroIMSS}\n  
				Monto Devoluciones: $${devoluciones}\n  
				Monto Caja Ahorro: $${cajaAhorro}\n  
				Monto Retenciones: $${retensiones}
				`;
			},

			formulaMontoBonos(item) {
				let bonos_ventas = item.bonos_ventas !== undefined && item.bonos_ventas !== null ? item.bonos_ventas : 0.00;
				let bonos_ri = item.bonos_ri !== undefined && item.bonos_ri !== null ? item.bonos_ri : 0.00;
				let bonos_puntualidad = item.bonos_puntualidad !== undefined && item.bonos_puntualidad !== null ? item.bonos_puntualidad : 0.00;

				this.formula_monto_bonos = `
				Monto Bonos Ventas: $${bonos_ventas}\n  
				Monto Bonos RI: $${bonos_ri}\n  
				Monto Bonos Puntualidad: $${bonos_puntualidad}
				`;
			},

			formulaMontoTotal(item) {
				let monto_laborado = item.monto_laborado !== undefined && item.monto_laborado !== null ? item.monto_laborado : 0.00;
				let compensaciones = item.compensaciones !== undefined && item.compensaciones !== null ? item.compensaciones : 0.00;
				let monto_horas_extras = item.monto_horas_extras !== undefined && item.monto_horas_extras !== null ? item.monto_horas_extras : 0.00;
				let comisiones_semana = item.comisiones_semana !== undefined && item.comisiones_semana !== null ? item.comisiones_semana : 0.00;
				let monto_total_rebajes = item.monto_total_rebajes !== undefined && item.monto_total_rebajes !== null ? item.monto_total_rebajes : 0.00;
				let monto_total_bonos = item.monto_total_bonos !== undefined && item.monto_total_bonos !== null ? item.monto_total_bonos : 0.00;

				this.formula_monto_total = `
				Monto Jornada Laboral: ${monto_laborado}\n  
				Monto Compensaciones: ${compensaciones}\n  
				Monto Horas Extras: ${monto_horas_extras}\n  
				Monto Comisiones: ${comisiones_semana}\n  
				Monto Bonos: ${monto_total_bonos}\n  
				Monto Rebajes: ${monto_total_rebajes} 
				`;
			},

      // = response.data.horasDobles
			getCiclos () {
				this.cargar = true
				this.ciclos = []

				this.$http.get('roles.ciclos.all').then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
							}
						}
					}

					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			goHorasExtras( name ){
				this.$router.push({name: "HorasExtras", params: { fecha: this.fecha, nombre: name }})
			},
			goPrenomina( name ){
				this.$router.push({name: "nominaActual", params: { fecha: this.fecha, nombre: name } })
			},
			goBonos( name ){
				this.$router.push({name: "BonosVentas", params: { fecha: this.fecha, nombre: name }})
			},
			goRebajes( name ){
				this.$router.push({name: "RebajesNomina", params: { fecha: this.fecha, nombre: name }})
			},
			goComisiones( name ){
				this.$router.push({name: "EncargadasComisiones", params: { fecha: this.fecha, nombre: name }})
			},

			exportExcel(data) {
				const wb = XLSX.utils.book_new();
				data.forEach(sheetData => {
					const ws = XLSX.utils.json_to_sheet(sheetData.data);
					XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
				});

				// Descarga el archivo Excel
				XLSX.writeFile(wb, 'Nomina y Prenomina.xlsx');
			},

			liberarNomina( ){

				const payload = {
      		id_ciclo: this.ciclo.id_ciclo,
      		semana  : this.selectedHeaders,
      		tipo    : 1 
      	}

      	this.cargar      = true
        this.$http.post('nomina.liberar', payload ).then(response=>{
          this.validarSuccess('Nómina liberada correctamente')
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			exportarInscripciones() {
				// const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo,  retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }) =>
				// 	({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, "Horas Extras": cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo, retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }));

				const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, monto_laborado, monto_horas_extras, comisiones_semana, monto_total_rebajes, monto_total_bonos, monto_total }) =>
					({ "ID": id,"Empleado":nombre_completo, "Puesto":puesto, "Monto Jornada Laboral":monto_laborado,  "Monto Horas Extras":monto_horas_extras,  "Monto Comisiones":comisiones_semana, "Monto Rebajes":monto_total_rebajes, "Monto Bonos":monto_total_bonos,  "Monto Total":monto_total }));

				const empleados2 = this.filterEntradas.map(({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, entrada1, salida1, horas_laboradas1,  entrada2, salida2, horas_laboradas2, entrada3, salida3, horas_laboradas3,  entrada4, salida4, horas_laboradas4,  entrada5, salida5, horas_laboradas5,  entrada6, salida6, horas_laboradas6, entrada7, salida7, horas_laboradas7 }) => 
					({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, "Sabado/Entrada": entrada1, "Sabado/Salida":salida1, "Sabado/Horas":horas_laboradas1,  "Domingo/Entrada":entrada2, "Domingo/Salida":salida2, "Domingo/Horas":horas_laboradas2,  "Lunes/Entrada":entrada3, "Lunes/Salida":salida3, "Lunes/Horas":horas_laboradas3,  "Martes/Entrada":entrada4, "Martes/Salida":salida4, "Martes/Horas":horas_laboradas4, "Miercoles/Entrada":entrada5, "Miercoles/Salida":salida5, "Miercoles/Horas":horas_laboradas5, "Jueves/Entrada":entrada6, "Jueves/Salida":salida6, "Jueves/Horas":horas_laboradas6, "Viernes/Entrada":entrada7, "Viernes/Salida":salida7, "Viernes/Horas":horas_laboradas7  }));

				const empleados3 = this.nomina_pasada.map(({ id_trabajador, empleado, puesto, plantel, monto_total_sin_comisiones, monto_total_actual, monto_total_diferencia}) => 
					({ id_trabajador, empleado, puesto, plantel, "Monto Anterior": monto_total_sin_comisiones, "Monto Actual": monto_total_actual, "Monto Diferencia": monto_total_diferencia }));

				const empleados4 = this.filterEntradasEncargadas.map(({ id, nombre_completo, puesto, plantel, comisiones_semana}) => 
					({ id, nombre_completo, puesto, plantel, "Comisiones": comisiones_semana }));


				// Llama a la función exportExcel para crear un archivo Excel con dos pestañas
				// this.exportExcel([
				// 	{ sheetName: 'Nomina', data: this.dataNominaAnterior },
				// 	{ sheetName: 'Prenomina', data: empleados2 }, // Segunda pestaña con los mismos datos
				// 	{ sheetName: 'Comparativa', data: empleados3 }, // Segunda pestaña con los mismos datos
				// 	{ sheetName: 'Comisiones', data: empleados4 } // Segunda pestaña con los mismos datos
				// ]);

				this.exportExcel([
					{ sheetName: 'Nomina', data: empleados },
					{ sheetName: 'Prenomina', data: empleados2 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comparativa', data: empleados3 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comisiones', data: empleados4 } // Segunda pestaña con los mismos datos
				]);
			},

			async subirRequi( ){

				await this.subirNominaINBI()
				await this.subirComisionesINBI()
				await this.subirNominaFAST()
				await this.subirComisionesFAST()

			},

			responder( ){

				const payload = {
					idnomina_estatus: this.renglonNomina.idnomina_estatus,
					estatus         : 0,
					id_usuario      : this.getdatosUsuario.id_trabajador,
					motivo          : this.respuesta,
					idusuarionomina : this.renglonNomina.id_usuario 
				}

				this.$http.post('responder.nomina', payload ).then(response=>{
          this.validarSuccess( 'Respuesta enviada' )
          this.initialize( )
          this.dialogComentarios = false
          this.respuesta = ''
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


			},

			async subirNominaINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirNominaFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => !el.plantel.match('CORPO') && el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			format( valor ){
			  return valor.toLocaleString('es-MX', {
			    style: 'currency',
			    currency: 'MXN'
			  });
      }
		},
	}
</script>




