import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

import { io }         from "socket.io-client";
import axios from 'axios';

var QRCode = require('qrcode')

export default {
	data () {
		return {
			socket:null,
      socketKPI: null,
      socketTwilio: null,
      mensajeProceso:'',
      alertaSinTeacher:{
        estatus: false,
        mensaje: '',
      },
      notificaciones:[],
		}
	},
	computed: {
    ...mapGetters('login' ,[ 'getdatosUsuario' ]),
		...mapGetters('twilio',[ 'getConversaciones', 'getConversacionesM', 'getConversacionesG', 'getConversacionesC', 'getConversacionesFast', 'getConversacionesCRM','getConversacionesCRMFast' ]),
	},

	mounted() {

    // let url = axios.defaults.baseURL.replace('kpi/', '')
    let url = 'https://escuelakpi.club/'

    // Modo servidor
    const path = '/ws/socket.io'
    
    // Socket para el KPI
    if( !this.socketKPI ){


      // iniciar el socket
      this.socketKPI = io(url, {
        transports: ["websocket"], path
      });

      // Este es el socket general
      this.socketKPI.on("connect", ( ) => {
        // Conectar cocket
        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'El socket se ha iniciado con exito'
        this.alertaSinTeacher.type    = 'success'
      });

      // El socket para cuando un teacher va a faltar a clases
      this.socketKPI.on('falta-teacher', ( body ) => {
        
        // Verificamos que el puesto pueda tener esas alertas
        if( [57,18,50,42,19, 11, 43 ].includes( this.getdatosUsuario.idpuesto ) ){

          const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

          // Comparamos si el usuario tiene asignados esos planteles para poder ver esa alerta
          if( planteles.includes( body.id_plantel )){
            this.alertaSinTeacher.estatus = true
            this.alertaSinTeacher.mensaje = body.mensaje
            this.alertaSinTeacher.type    = 'success'
            this.getNotificaciones()
          }
        }
      })

      // Genera el socket para escuchar un ticket
      this.socketKPI.on('respuesta-ticket', ( body ) => {
        // Solo si es el mismo usuario, se muestra la alerta
        if( this.getdatosUsuario.iderp == body.id_usuario ){
          this.alertaSinTeacher.estatus = true
          this.alertaSinTeacher.mensaje = body.mensaje
          this.alertaSinTeacher.type    = body.type ? body.type : 'success'
        }
      })

      // Socket para saber si estoy desconectado del socket
      this.socketKPI.on("disconnect", () => {
        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'Tu socket se ha Desconectado'
        this.alertaSinTeacher.type    = 'error'
      });

      // // Socket para escuchar si se envió o recibió algún mensaje
      this.socketKPI.on("recibir-mensaje-twilio", ( body ) => {

        this.validarMensajeEntrante( body )

        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'Se ha recibido un mensaje'
        this.alertaSinTeacher.type    = 'success'
      });

    }

    // Socket para el SISTEMA DE TWILIO
    if( !this.socketTwilio ){

      let urlTwilio = 'https://testfast.club/'

      // iniciar el socket
      this.socketTwilio = io(urlTwilio, {
        transports: ["websocket"], path
      });

      // Este es el socket general
      this.socketTwilio.on("connect", ( ) => {
        // Conectar cocket
        console.log('estoy conectado al socket del TWILIO')
        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'TWILIO iniciado con exito'
        this.alertaSinTeacher.type    = 'success'
      });

      // Socket para saber si estoy desconectado del socket
      this.socketTwilio.on("disconnect", () => {
        console.log('Desconectado del socket TWILIO');
        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'TWILIO desconectado'
        this.alertaSinTeacher.type    = 'error'
      });

      // Socket para escuchar si se envió o recibió algún mensaje
      this.socketTwilio.on("recibir-mensaje-twilio", ( body ) => {

        this.validarMensajeEntrante( body )

        this.alertaSinTeacher.estatus = true
        this.alertaSinTeacher.mensaje = 'Se ha recibido un mensaje'
        this.alertaSinTeacher.type    = 'success'
      });

    }
  },

  methods: {
    
    ...mapActions('twilio', ['guardarConversaciones', 'guardarConversacionesM', 'guardarConversacionesG', 'guardarConversacionesC', 'guardarConversacionesFast', 'guardarConversacionesCRM', 'guardarConversacionesCRMFast'] ),


    validarEstatus () {
      this.mensajeProceso = ''

    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

    	// Cargamos y abrimos un cuadro de dialogo 
      this.cargar = true

    	// Creamos el payload que vamos a enviar
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }

      // Genero la ruta oficial para hacer la petición
      this.url_servidor = this.getdatosUsuario.url_servidor + 'whatsapp.activo'

      // Hago la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	this.cargar = false
      	this.dialog = true
      }).catch( error =>{
      	this.estatusWhats   = 3
      	this.dialog         = true
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // Función para escanear el código QR
		escanerQR () {

    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

    	// Formamos el payload para cargar el usuario
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }
      
      this.cargar = true
    	this.dialog = false

      // Genero la ruta oficial para hacer la petición

      const path = 'whatsapp.inicializar'
      this.url_servidor = this.getdatosUsuario.url_servidor + path

      // Realizo la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	this.dialog = true
      }).catch( error =>{
      	this.dialog = true
        this.validarError( error.response.data.message )
      })
    },

    // Detruir la sesión de wpp por si hay algún error en todo esto
		cerrarSesion () {
    	// Validamos la información del usuario y que tenga su WhatsAppservidor
    	if( !this.getdatosUsuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

      this.cargar = true
    	this.dialog = false

    	// Formamos el payload para cargar el usuario
      const payload = {
      	from: this.getdatosUsuario.whatsappservidor
      }

      // Genero la ruta oficial para hacer la petición
      this.url_servidor = this.getdatosUsuario.url_servidor + 'whatsapp.destruir'
      
      // Realizo la petición
      return this.$http.post(this.url_servidor, payload).then(response=>{
      	// Indico que ya no hay una sessión
      	this.estatusWhats = 2
      	this.cargar = false
      	this.dialog = true
      }).catch( error =>{
      	this.dialog = true
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    
    getNotificaciones(){
      // Realizo la petición
      return this.$http.get('notificaciones/' + this.getdatosUsuario.iderp ).then(response=>{
        // Indico que ya no hay una sessión
        this.notificaciones = response.data

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },


    validarMensajeEntrante( body ){
      // Sacamos el usuario que envío el mensaje
      const { usuario, twilio, mensaje, type } = body

      let conversaciones = []

      if( twilio == 'whatsapp:+5218187047279' ){
        conversaciones = [...this.getConversacionesM];
      }

      if( twilio == 'whatsapp:+5215545703336' ){
        conversaciones = [...this.getConversacionesC];

      }

      if( twilio == 'whatsapp:+5213316971156' ){
        conversaciones = [...this.getConversacionesG];
      }

      if( twilio == 'whatsapp:+5218123255321' ){
        conversaciones = [...this.getConversacionesFast];
      }

      if( twilio == 'whatsapp:+5218124384082'){
        conversaciones = [...this.getConversacionesCRM];
      }

      if( twilio == 'whatsapp:+5218124387901'){
        conversaciones = [...this.getConversacionesCRMFast];
      }

      // Ahora lo buscamos en las conversaciones
      const existeUsuario = conversaciones.find( el => el.usuario == usuario )

      // Si existe.... hay que cambiarle el estatus
      if( existeUsuario ){

        for( const i in conversaciones ){

          const { sinleer } = conversaciones[i]

          if( conversaciones[i].usuario == usuario ){

            conversaciones[i].mensaje = mensaje

            // Validar que el chat que estoy viendo no seaaaaa el que me envío mensaje
            if( this.chat ){

              // Si no son iguales, se activa el sin leer
              if( this.chat.usuario !=  conversaciones[i].usuario ){

                // Decirle al sistema que hay un mensaje sin leer
                conversaciones[i].sinleer = 1 

              }else{

                // son iguales, no se activa el sin leer, pero se manda a leer el chat
                // this.verChat( this.chat )
                this.mensajes.push(body)

              }

            }else{

              // Decirle al sistema que hay un mensaje sin leer
              conversaciones[i].sinleer += 1 
              conversaciones[i].mio     = 0 
              conversaciones[i].type    = type 

            }


            // Activamos el sonido
            if( this.sonido ){
              this.sonido.play().catch((error) => {
                console.error("Error al reproducir el audio:", error);
              });
            }

          }

        }

      }

      if( twilio == 'whatsapp:+5218187047279' ){
        this.guardarConversacionesM( conversaciones )
      }

      if( twilio == 'whatsapp:+5215545703336' ){
        this.guardarConversacionesC( conversaciones )
      }

      if( twilio == 'whatsapp:+5213316971156' ){
        this.guardarConversacionesG( conversaciones )
      }

      if( twilio == 'whatsapp:+5218123255321' ){
        this.guardarConversacionesFast( conversaciones )
      }

      if( twilio == 'whatsapp:+5218124384082'){
        this.guardarConversacionesCRM( conversaciones )
      }

      if( twilio == 'whatsapp:+5218124387901'){
        this.guardarConversacionesCRMFast( conversaciones )
      }


    }

  },
}