<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="ma-0 pa-0">
				<v-card class="elevation-0 transparent">
					<v-card-text >

		      	<div class="black--text text-h6">
		      		Total de horas:             {{ totalHoras }}
		      	</div>
		      	<br/>
		      	<v-row>

							<v-btn
								color="red"
								dark
								class="mr-2"
								@click="bloquear( 2 )"
								small
								tile
							>
								Aceptar, Bloquear
								<v-icon right small >mdi-lock</v-icon>
							</v-btn>

							<v-btn
								color="green"
								dark
								class="mr-2"
								@click="bloquear( 1 )"
								small
								tile
							>
								Liberar
								<v-icon right small >mdi-lock</v-icon>
							</v-btn>

		      		<v-btn
								color="primary"
								dark
								@click="inciarNominaSabtina()"
								small
								tile
							>
								Actualizar
							</v-btn>
		      		<v-spacer></v-spacer>
			      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
			          <v-text-field
			            v-model="search"
			            dense
			            filled
			            single-line
			            label="Buscar"
			            append-icon="mdi-magnify"
			          ></v-text-field>  
			        </v-col>
		      	</v-row>

		      	<v-row>
		      		<v-col cols="12" class="black--text">
		      			<b>% aceptación Quincena:</b> {{ porcentajeAceptacionQ }}
		      			<br/>
		      			<b>% aceptación Bono:</b> {{ porcentajeAceptacionB }}
		      			<br/>
		      			<b>% aceptación Sabatina:</b> {{ porcentajeAceptacionA }}
		      		</v-col>
		      	</v-row>

		      	<v-row v-if="!liberar">
		      		<v-col cols="12" class="black--text">
		      			<label>Maestros sin categoria</label>
		      			<span v-for="( ma , i ) in maestros" :key="i" class="black--text"> {{ ma }}<br/></span>
		      		</v-col>
		      	</v-row>

		      	<v-row v-if="!liberar">
		      		<v-col cols="12" class="black--text">
		      			<v-alert
						      dense
						      outlined
						      type="error"
						    >
						      Agrega la categoría a los maestros para poder liberar la nómina
						    </v-alert>
		      		</v-col>
		      	</v-row>

		        <v-tabs
		          v-model="tab"
		          align-with-title
		        >
		          <v-tabs-slider></v-tabs-slider>

		          <v-tab v-if="liberar">Nómina</v-tab>
		          <v-tab v-if="liberar">Disp. Horas Sab</v-tab>
		          <v-tab v-if="[1,3].includes( selectedHeaders ) && liberar">Disp. Horas Quin</v-tab>
		          <v-tab v-if="liberar">Comparativa Sab</v-tab>
		          <v-tab v-if="[1,3].includes( selectedHeaders ) && liberar">Comparativa Quin</v-tab>
		          <v-tab v-if="[1].includes( selectedHeaders ) && liberar">Comparativa Bonos</v-tab>
		        </v-tabs>

		        <v-tabs-items v-model="tab">

		        	<!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>

				          	<v-radio-group
								      v-model="opcionFiltro"
								      row
								      dense
								    >
								      <v-radio
								        label="Pendiente"
								        :value="0"
								      ></v-radio>
								      <v-radio
								        label="Aceptados"
								        :value="1"
								      ></v-radio>
								      <v-radio
								        label="Rechazados"
								        :value="2"
								      ></v-radio>
								      <v-radio
								        label="Todos"
								        :value="3"
								      ></v-radio>

								      <v-radio
								        label="Sin categoría"
								        :value="4"
								      ></v-radio>

								    </v-radio-group>

								    <v-spacer></v-spacer>
										<v-btn 
											class="mr-2" 
											small 
											dark 
											color="green"
											tile
											@click="exportar()">
											<v-icon>mdi-microsoft-excel</v-icon>
										</v-btn>

						        <v-data-table
						          :headers="headersNomina"
						          :items="filterTeachers"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        	<template v-slot:item.aceptado="{ item }">
			            			<v-chip v-if="(!item.aceptado)    " small color="grey"  dark >Pendiente</v-chip>
			            			<v-chip v-if="(item.aceptado == 1)" small color="green" dark >Aceptada</v-chip>
			            			<v-chip v-if="(item.aceptado == 2)" small color="red"   dark >Rechazada</v-chip>
			            		</template>

			            		<template v-slot:item.hsbc="{ item }">
			            			<v-chip v-if="(item.hsbc == 1)" small color="green" dark >Si</v-chip>
			            			<v-chip v-if="(item.hsbc == 2)" small color="red"   dark >No</v-chip>
			            		</template>

			            		<template v-slot:item.pago_quincena="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_quincena" 
			            				:color="item.estatus_quincena == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_quincena, 2 , item )"
			            			>
			            				{{ item.pago_quincena }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_quincena, 2 , item )">{{ item.pago_quincena }}</v-chip>
			            		</template>

			            		<template v-slot:item.PAGO_SABADO="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_sabatina" 
			            				:color="item.estatus_sabatina == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_sabatina, 1 , item )"
			            			>
			            				{{ item.PAGO_SABADO }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_sabatina, 1 , item )">{{ item.PAGO_SABADO }}</v-chip>
			            		</template>

			            		<template v-slot:item.bono_teacher="{ item }">
			            			<v-chip 
			            				v-if="item.estatus_bono" 
			            				:color="item.estatus_bono == 1 ? 'green' : 'red'" 
			            				dark
			            				small 
			            				@click="verComentarios( item.comentarios_bono, 3 , item )"
			            			>
			            				{{ item.bono_teacher }}
			            			</v-chip>
			            			
			            			<v-chip v-else color="grey" small dark @click="verComentarios( item.comentarios_bono, 3 , item )">{{ item.bono_teacher }}</v-chip>
			            		</template>

						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Disperción de horas del sabatino -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headers2"
						          :items="teachers"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Disperción de horas del Quincena -->
				      <v-tab-item v-if="[1,3].includes( selectedHeaders )">
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headers2Q"
						          :items="teachersQ"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa del Sabatina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
				          	<v-row>
				          		<v-col cols="12" md="5">
				          			<label class="black--text"><b>Semana {{ selectedHeaders - 1 }}</b></label>
				          			<v-data-table
								          :headers="headers3"
								          :items="tabla2Comparativa"
								          class="elevation-0"
								          dense
								          :search="search"
								        >
								        </v-data-table>
								        <label class="text-h6"><b>Costo por hora: {{ costoHora1 }}</b></label>
				          		</v-col>

				          		<v-col cols="12" md="7">

				          			<label class="black--text"><b>Semana {{ selectedHeaders }}</b></label>
				          			<v-data-table
								          :headers="headers4"
								          :items="tabla1Comparativa"
								          class="elevation-0"
								          dense
								          :search="search"
								        >
								        </v-data-table>
								        <label class="text-h6"><b>Costo por hora: {{ costoHora2 }}</b></label>
								        <br/>

								      	<b>Notas: </b>
								      	<br/>  

								      	<p 
								          class="text" 
								          style="white-space: pre-line"
								        >
								      		{{ notasSabatino }}
								        </p>
				          		</v-col>
				          	</v-row>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de la quincena -->
				      <v-tab-item v-if="[1,3].includes( selectedHeaders )">
				        <v-card flat>
				          <v-card-text>
				          	<v-row>
				          		<v-col cols="12" md="5">
				          			<label class="black--text"><b>Quincena {{ 1 }}</b></label>
				          			<v-data-table
								          :headers="headers3"
								          :items="tabla2ComparativaQ"
								          class="elevation-0"
								          dense
								          :search="search"
								        >
								        </v-data-table>
								        <label class="text-h6"><b>Costo por hora: {{ costoHora1 }}</b></label>
				          		</v-col>

				          		<v-col cols="12" md="7">

				          			<label class="black--text"><b>Quincena {{ 2 }}</b></label>
				          			<v-data-table
								          :headers="headers4"
								          :items="tabla1ComparativaQ"
								          class="elevation-0"
								          dense
								          :search="search"
								        >
								        </v-data-table>
								        <label class="text-h6"><b>Costo por hora: {{ costoHora2 }}</b></label>
								        <br/>

								      	<b>Notas: </b>
								      	<br/>  

								      	<p 
								          class="text" 
								          style="white-space: pre-line"
								        >
								      		{{ notasQuincena }}
								        </p>
				          		</v-col>
				          	</v-row>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Comparativa de bonos -->
				      <v-tab-item v-if="[1].includes( selectedHeaders )">
				        <v-card flat>
				          <v-card-text>
				          	<v-row>
				          		<v-col cols="12">
				          			<v-data-table
								          :headers="headersBono"
								          :items="tablaBonos"
								          class="elevation-0"
								          dense
								          :search="search"
								        >
								        </v-data-table>
				          		</v-col>
				          	</v-row>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>
				    </v-tabs-items>

		      </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<v-dialog
		  v-model="dialogVerComentario"
		  max-width="500px"
		  v-if="comentarios"
		>
			<v-card>
				<v-card-title primary-title>
				  Comentarios de nómina
				</v-card-title>
			  <v-card-actions>
			  	<v-row>
			  		<v-col cols="12"
			  			v-for="(mensaje, i) in comentarios" :key="i"
			  		>
					  	<p 
					  		:class="`pa-2 ma-0 mb-4 ${ mensaje.id_usuario != getdatosUsuario.iderp ? 'mensajeIzq' : 'mensajeDer' }`">
					    	{{ mensaje.comentarios }}
					    	<br/>
					    	{{ mensaje.nombre_completo }}
					  	</p>
			  		</v-col>
			  	</v-row>
			    <br/>
			  </v-card-actions>
				<v-card-text>
			    <v-textarea
			  		v-model="respuesta"
			  		filled
			  		:rows="3"
			  		auto-grow
			  		label="respuesta al colaborador"
			  	></v-textarea>
			  	<v-btn 
			  		color="success"
			  		@click="responder( )"
			  		tile
			  	>
			  		responder
			  	</v-btn>
			  </v-card-text>
			</v-card>	
		  
		</v-dialog>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>

<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import validarErrores       from '@/mixins/validarErrores'
	import XLSX                 from 'xlsx'
	import funcionesExcel       from '@/mixins/funcionesExcel'
	import mixinNominaSabatina  from '@/mixins/mixinNominaSabatina'
	import mixinNominaQuincena  from '@/mixins/mixinNominaQuincena'

	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel, mixinNominaSabatina, mixinNominaQuincena ],

		props:[
			'selectedHeaders',
			'ciclo',
		],

		data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      opcionFiltro: 3,

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      teachersBonos: [],

      teachers:[],
      teachersQ:[],

      headersNomina: [
        { text: 'id'                , value: 'id_teacher'           },
        { text: 'Teacher'           , value: 'teacher'              },  
        { text: 'Categoría'         , value: 'aceptado'             },  
        { text: 'HSBC'              , value: 'hsbc'                 },  
        { text: 'Plantel'           , value: 'plantel'              },  
	      { text: 'existeCategoria'   , value: 'existeCategoria'      },  
        { text: 'PAGO_SABADO'       , value: 'PAGO_SABADO', align: 'end'},  
      ],

      headers3: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
      ],

      headers2: [
        { text: 'id'              ,  value: 'id_teacher'         },
        { text: 'Teacher'         ,  value: 'teacher'            },  
        { text: 'H_SABADO_FAST'   ,  value: 'HORAS_SABADO_FAST'  },  
        { text: 'H_SABADO_INBI'   ,  value: 'HORAS_SABADO_INBI'  },  
        { text: 'H_SABADO_TEENS'  ,  value: 'HORAS_SABADO_TEENS' },  
        { text: 'TOTAL_HORAS'     ,  value: 'TOTAL_HORAS'        },  
        { text: 'Costo H'         ,  value: 'categoria'          },  
        { text: 'PAGO_SABADO'     ,  value: 'PAGO_SABADO'        },  
      ],

      headers4: [
        { text: 'valor hora' , value: 'valor_hora'    },
        { text: '# horas'    , value: 'numero_horas'  },  
        { text: 'Costo'      , value: 'costo'         },  
        { text: 'Diferencia' , value: 'diferencia'    },  
        { text: 'Motivo'     , value: 'motivo'        },  
      ],

      headers2Q: [
        { text: 'id'              ,  value: 'id_teacher'               },
        { text: 'Teacher'         ,  value: 'teacher'                  },  
        { text: 'H_LV_FAST'       ,  value: 'HORAS_ENTRE_SEMANA_FAST'  },  
        { text: 'H_D_FAST'        ,  value: 'HORAS_DOMINGO_FAST'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_FAST'           },  
        { text: 'H_LV_INBI'       ,  value: 'HORAS_ENTRE_SEMANA_INBI'  },  
        { text: 'H_D_INBI'        ,  value: 'HORAS_DOMINGO_INBI'       },  
        { text: 'H_ONL_TEENS'     ,  value: 'HORAS_LMV_INBI'           },
        { text: 'TOTAL_HORAS'     ,  value: 'HORAS_TOTALES'            },  
        { text: 'Costo H LV'      ,  value: 'categoria_lv'             }, 
        { text: 'Costo H D'       ,  value: 'categoria_d'              }, 
        { text: 'PAGO_TOTAL'      ,  value: 'PAGO_TOTAL'               },  
      ],

      headersBono: [
        { text: 'id'              , value: 'id_teacher'   },
        { text: 'Teacher'         , value: 'teacher'      },
        { text: 'PAGO Anterior'   , value: 'bono_anterior', align: 'end' } ,
        { text: 'PAGO BONO'       , value: 'bono_teacher', align: 'end' },  
        { text: 'diferencia'      , value: 'diferencia', align: 'end' }  
      ],

			search:'',
      gruposActivos:[],

      tab: null,

      tabla1Comparativa: [],
      tabla2Comparativa: [],

      tabla1ComparativaQ: [],
      tabla2ComparativaQ: [],

      categorias1:[],
      categorias2:[],
      categorias1Q:[],
      categorias2Q:[],


      costoHora1: 0,
      costoHora2: 0,
      horasImpartidas: 0,
      horasImpartidasAnt: 0,

      costoHora1Q: 0,
      costoHora2Q: 0,
      horasImpartidasQ: 0,
      horasImpartidasAntQ: 0,

      gruposQuincena: [],
      cicloAnt: null,
      comentariosT:[],
      dialogVerComentario: false,
      comentarios:[],
      porcentajeAceptacionQ:0,
			porcentajeAceptacionB:0,
			respuesta:'',
			gruposQuincenaAnt:[],

			gruposAllBono:[],
			tipo_nomina: 0,
			renglonNomina: null,
			rebajes: [],
			bonosTeachers:[],

			liberar: false,
			maestros: []
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			porcentajeAceptacionA( ){
				return  ((this.teachers.filter( el => el.estatus_sabatina == 1 ).length / this.teachers.filter( el => el.PAGO_SABADO != '$0.00' ).length ) *100).toFixed(2)
			},

			filterTeachers( ){


				if( ![3,4].includes(this.opcionFiltro) ){


					if( this.opcionFiltro == 2 ){

						// return this.teachers.filter( el => el.estatus_quincena == 2 )
						// return this.teachers.filter( el => el.estatus_quincena == 2 || el.estatus_bono == 2 )
						return this.teachers.filter( el => el.estatus_sabatina == 2 )

					}else if( this.opcionFiltro == 1 ){

						// return this.teachers.filter( el => el.estatus_quincena == 1 && el.estatus_bono == 1 )
						// return this.teachers.filter( el => el.estatus_quincena == 1 && el.pago_quincena != '$0.00' )
						return this.teachers.filter( el => el.estatus_sabatina == 1 )

					}else{

						// return this.teachers.filter( el => el.estatus_quincena == 0  )
						// return this.teachers.filter( el => el.estatus_quincena == 0 || el.estatus_bono == 0 )
						return this.teachers.filter( el => el.estatus_sabatina == 0 )
					
					}

				}else{

					if( this.opcionFiltro == 3 ){

						return this.teachers

					}else{

						return this.teachers.filter( el => !el.existeCategoria )

					}

				}
			},


			tablaBonos( ){

				let bonos = this.teachers.filter( el => el.bono_teacher != '$0.00' ) 

	    	let bonos_anteriores = [
	    		{ id_teacher: 1280, monto: '$350', teacher: 'ABIGAIL DEL ANGEL MENDOZA'},
					{ id_teacher: 1528, monto: '$500', teacher: 'ALEJANDRA GABRIELA GOMEZ HERNANDEZ'},
					{ id_teacher: 1804, monto: '$500', teacher: 'Alfredo Lopez Nunez'},
					{ id_teacher: 1526, monto: '$350', teacher: 'AMELY ALEJANDRA ASIS CHAVEZ LOPEZ'},
					{ id_teacher: 1544, monto: '$350', teacher: 'ANA ROSA FLORES SALDAÑA'},
					{ id_teacher: 1805, monto: '$500', teacher: 'ANDREA MATA ZEPEDA'},
					{ id_teacher: 1791, monto: '$350', teacher: 'ANDREA VILLARREAL FARIAS'},
					{ id_teacher: 1798, monto: '$500', teacher: 'BEATRIZ ALEJANDRA LLANES CERVANTES'},
					{ id_teacher: 1496, monto: '$500', teacher: 'Carlos Javier Mendez Madrid'},
					{ id_teacher: 464, monto: '$350', teacher: 'Cecilia Aidé Padilla Mendoza'},
					{ id_teacher: 1137, monto: '$350', teacher: 'DELIAN GUADALUPE MUÑOZ PEÑA'},
					{ id_teacher: 1534, monto: '$500', teacher: 'edson giovani lerma Hernandez'},
					{ id_teacher: 1318, monto: '$500', teacher: 'EMILIO REYNA VALDEZ'},
					{ id_teacher: 1512, monto: '$500', teacher: 'EMMA LISSETH MIRANDA ENRIQUEZ'},
					{ id_teacher: 1675, monto: '$500', teacher: 'FATIMA ARLETTECRUZBERNAL'},
					{ id_teacher: 495, monto: '$350', teacher: 'Frida Sophia Perez Trujillo'},
					{ id_teacher: 545, monto: '$500', teacher: 'Génesis Rodríguez Burrola'},
					{ id_teacher: 1510, monto: '$350', teacher: 'GERARDO MARCELO ARIZPE ESTRADA'},
					{ id_teacher: 1680, monto: '$350', teacher: 'GUADALUPE GODINEZ BAUTISTA'},
					{ id_teacher: 1680, monto: '$350', teacher: 'GUADALUPE GODINEZ BAUTISTA'},
					{ id_teacher: 798, monto: '$350', teacher: 'Héctor Espino Portal'},
					{ id_teacher: 1462, monto: '$350', teacher: 'humberto yovanny rodriguez perez'},
					{ id_teacher: 1319, monto: '$500', teacher: 'IVANNA LEIJA TREVIÑO'},
					{ id_teacher: 1685, monto: '$500', teacher: 'JAZMIN NAVARRETE'},
					{ id_teacher: 1090, monto: '$350', teacher: 'Jesus Alejandro Castillo Rodriguez'},
					{ id_teacher: 1126, monto: '$350', teacher: 'JOSE BENJAMIN LOPEZ ECHAVARRIA'},
					{ id_teacher: 882, monto: '$350', teacher: 'Jose Reveles Saenz'},
					{ id_teacher: 987, monto: '$350', teacher: 'Karina Michell Medina Zarate'},
					{ id_teacher: 1487, monto: '$500', teacher: 'MARIA FERNANDA ESTRADA VALDIVIA'},
					{ id_teacher: 853, monto: '$500', teacher: 'María Fernanda Treviño Llanas'},
					{ id_teacher: 1535, monto: '$350', teacher: 'mariel sandoval torres'},
					{ id_teacher: 1762, monto: '$500', teacher: 'Mario Bernabe Zamudio Alarcon'},
					{ id_teacher: 1752, monto: '$350', teacher: 'Mayela Ruiz Monte de oca'},
					{ id_teacher: 1665, monto: '$350', teacher: 'Oscar Alberto Vargas Jiménez'},
					{ id_teacher: 1447, monto: '$350', teacher: 'osmar said sandoval aguilar'},
					{ id_teacher: 1310, monto: '$500', teacher: 'ROBESPIERRE LANDA GUZMAN'},
					{ id_teacher: 1607, monto: '$350', teacher: 'SARA EMILIA RODRIGUEZ MENDOZA'},
					{ id_teacher: 1841, monto: '$350', teacher: 'TALIA LINNETH WILLIS LOPEZ'},
					{ id_teacher: 1869, monto: '$350', teacher: 'TATIANA ESCAMILLA ACOSTA'},
					{ id_teacher: 1600, monto: '$350', teacher: 'VALERIA AISEL GUERRERO SUSTAITA'},
					{ id_teacher: 1627, monto: '$500', teacher: 'Veronica Gonzalez Sanchez'},
					{ id_teacher: 1523, monto: '$500', teacher: 'VICTORIA VIRYDIANA VIDAL VELAZQUEZ'},
					{ id_teacher: 1589, monto: '$350', teacher: 'YADEL ESTEFANI QUINTERO RODRIGUEZ'},
	    	]

	    	for( const i in bonos ){

	    		const { id_teacher, bono_teacher } = bonos[i]

	    		const existeAnterior = bonos_anteriores.find( el => el.id_teacher == id_teacher )

	    		let bono_anterior_monto = existeAnterior ? parseFloat( existeAnterior.monto.replace('$','').replace(',','') ) : 0

	    		bonos[i].bono_teacher = bono_teacher ? bono_teacher : this.format( 0 )

	    		let nuevo_monto = bono_teacher ? parseFloat( bono_teacher.replace('$','').replace(',','') ) : 0

	    		let diferencia = nuevo_monto - bono_anterior_monto 

	    		bonos[i]['bono_anterior'] = existeAnterior ? existeAnterior.monto : '$0.00'
	    		bonos[i]['diferencia'] = this.format( diferencia )
 
	    	}


	    	for( const i in bonos_anteriores ){

	    		const { id_teacher, monto, teacher } = bonos_anteriores[i]

	    		let existeTeacher = bonos.find( el => el.id_teacher == id_teacher )

	    		if( !existeTeacher ){
	    			bonos.push({
			    		id_teacher,
			        teacher,
			        bono_anterior      : monto,
			        bono_teacher       : '$0.00',
			        diferencia         : '-' + monto ,
			    	})
	    		}


	    	}

	    	bonos.push({
	    		id_teacher: 100000,
	        teacher   : 'TOTAL',
	        bono_anterior      : this.format( bonos.map(item => parseFloat( item.bono_anterior.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	        bono_teacher       : this.format( bonos.map(item => parseFloat( item.bono_teacher.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	        diferencia         : this.format( bonos.map(item => parseFloat( item.diferencia.replace('$','').replace(',','') ) ).reduce((prev, curr) => prev + curr, 0) ),
	    	})


				return  bonos
			}

		},

		watch: {

			selectedHeaders( ){
				this.headersNomina = [
	        { text: 'id'              , value: 'id_teacher'      },
	        { text: 'Teacher'         , value: 'teacher'         },  
	        { text: 'Categoría'       , value: 'aceptado'        },  
	        { text: 'HSBC'            , value: 'hsbc'            },  
	        { text: 'Plantel'         , value: 'plantel'         },  
	        { text: 'existeCategoria' , value: 'existeCategoria' },  
	        { text: 'PAGO_SABADO'     , value: 'PAGO_SABADO'     },  
	      ]

				if( [1,3].includes( this.selectedHeaders ) ){
					this.headersNomina.push({
	        	text: 'PAGO QUINCENA', 
	        	value: 'pago_quincena',
	        	align: 'end'
					})
				}


				if( [1].includes( this.selectedHeaders ) ){
					this.headersNomina.push({
	        	text: 'PAGO BONO', 
	        	value: 'bono_teacher',
	        	align: 'end'
					})
				}
			}
			
		},

		async created () {


			this.headersNomina = [
        { text: 'id'              , value: 'id_teacher'      },
        { text: 'Teacher'         , value: 'teacher'         },  
        { text: 'Categoría'       , value: 'aceptado'        },  
        { text: 'HSBC'            , value: 'hsbc'            },  
        { text: 'Plantel'         , value: 'plantel'         },  
	      { text: 'existeCategoria' , value: 'existeCategoria' },  
        { text: 'PAGO_SABADO'     , value: 'PAGO_SABADO'     },  
      ]

			if( this.selectedHeaders == 1 ){
				this.headersNomina.push({
        	text: 'PAGO QUINCENA', 
        	value: 'pago_quincena',
        	align: 'end'
				})

				this.headersNomina.push({
        	text: 'PAGO BONO', 
        	value: 'bono_teacher',
        	align: 'end'
				})
			}

			if( this.selectedHeaders == 3 ){
				this.headersNomina.push({
        	text: 'PAGO QUINCENA', 
        	value: 'pago_quincena',
        	align: 'end'
				})
			}

			this.headersNomina.push({
      	text: 'PAGO_DOBLES', 
      	value: 'PAGO_DOBLES',
      	align: 'end'
			})

			this.headersNomina.push({
      	text: 'REBAJES', 
      	value: 'REBAJES',
      	align: 'end'
			})
			
			this.headersNomina.push({
      	text: 'PAGO TOTAL Q', 
      	value: 'total_final',
      	align: 'end'
			})
			// Depende de la semana, son los campos que se van a llenar o a agregar en los headers de la tabla 1

			await this.getTeachersSinCategoria( )
			await this.inciarNominaSabtina()

		},

		methods:{

			getTeachersSinCategoria( ){
				this.liberar = false
				this.$http.get('teachers.sin.categoria').then(response=>{

					this.liberar  = response.data.length > 0 ? false : true
					this.maestros = response.data.map(( registro ) => { return registro.nombre_completo })
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			verComentarios( comentarios, tipo_nomina , item ){
				console.log( item )
				this.renglonNomina = item
				this.comentarios = comentarios
				this.tipo_nomina = tipo_nomina
				this.dialogVerComentario = true
			},

			exportar( ){
        this.exportExcel( this.filterTeachers  , 'teachers')
			},

			responder ( ) {
				this.cargar = true
				const payload = {
					id_usuario : this.getdatosUsuario.iderp,  
					id_teacher : this.renglonNomina.id_teacher,  
					estatus    : [334,966].includes( this.getdatosUsuario.iderp ) ? 0 : 2,
					id_ciclo   : this.ciclo.id_ciclo,
					semana     : 4,// this.selectedHeaders,
					motivo     : this.respuesta,
					tipo_nomina: this.tipo_nomina,
				}

				this.$http.post('teachers.estatus.responder', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.respuesta    = ''
					this.dialogVerComentario = false
					this.cargar       = false
					this.initialize()
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},
    }
	}
</script>




