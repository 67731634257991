<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Asignación de contactos
            <v-spacer></v-spacer>

            <v-btn
              color="orange"
              class="mr-2"
              dark
              @click="dialogDiferencias.estatus = true"
              small
              tile
            >
              <v-icon small left>mdi-account-convert</v-icon>
              Diferencias
            </v-btn>

            <v-btn
              color="green"
              class="mr-2"
              dark
              @click="dialogVendedora.estatus = true"
              small
              tile
            >
              <v-icon small left>mdi-account</v-icon>
              Reporte
            </v-btn>

            <!-- Abrir modal para reasignar twilio -->
            <v-btn
              color="red"
              class="mr-2"
              dark
              @click="dialogTwilio.estatus = true"
              small
              tile
            >
              <v-icon small left>mdi-account-question</v-icon>
              Twilio
            </v-btn>


            <v-btn
              color="primary"
              class="mr-2"
              dark
              @click="dialog = true"
              small
              tile
            >
              <v-icon small left>mdi-plus</v-icon>
              Nuevo LEAD
            </v-btn>

            <v-btn 
              color="primary" 
              small 
              @click="initialize()"
              tile
            >Actualizar</v-btn>


          </v-card-title>

          <v-card-text>
            <v-row >

              <v-col cols="12">
                <v-radio-group
                  v-model="escuela"
                  row
                  hide-details
                >
                  <v-radio
                    label="INBI"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="FAST"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechaini"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha inicio"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechafin"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha final"
                  clearable
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                label="Buscar Prospecto"
                dense
                v-model="search"
                filled
                append-icon="mdi-magnify"
                dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <!--Lista de prospectos-->
                <v-data-table
                  :headers="headers2"
                  :items="contadorVendedoras"
                  class="elevation-0"
                  :search="search2"
                  dense
                >


                </v-data-table>
                
              </v-col>
            </v-row>

            <!--Lista de prospectos-->
            <v-data-table
              :headers="headers"
              :items="prospectos"
              class="elevation-0"
              :search="search"
              dense
              >

              <template v-slot:item.name="{ item }">
                <div @click="(prospecto = item), (dialogUsuario = true)" type="button">{{ item.name }}</div>
              </template>

              <template v-slot:item.ver="{ item }">
                <v-icon small class="mr-2" @click="(prospecto = item), (dialogUsuario = true)" color="primary"> mdi-pencil </v-icon>
              </template>

              <template v-slot:item.estatus="{ item }">
                <v-chip small dense v-if="item.idetapa == 2" color="green" dark>Nuevo</v-chip>
                <v-chip small dense v-if="item.idetapa == 1" color="red" dark>Atrasado</v-chip>
              </template>


              <template v-slot:item.match="{ item }">
                <v-chip small dense v-if="item.match" color="green" dark></v-chip>
                <v-chip small dense v-else color="red" dark></v-chip>
              </template>
            </v-data-table>
            
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog 
      v-model="dialogUsuario" 
      max-width="500px"
      >

      <!-- Lista de vendedoras -->

      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Asignación de contactos</span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            :items="filterVendedoras"
            v-model="usuario"
            label="Selecciona vendedora"
            item-value="iderp"
            item-text="nombre_plantel"
            filled
            dense
            hide-details
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="close"
          > 
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar 
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn 
            color="green"
            dark
            small
            tile 
            @click="actualizar()"
          >
            Guardar
            <v-icon small right>mdi-content-save</v-icon>
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Agregar LEAD -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <nuevo_lead 
        :formTitle="formTitle"
        :editedItem="editedItem"
        :cursos_escuela="cursos_escuela"
        :vendedoras_plantel="filterVendedoras"
        :fuentes="fuentes"
        :detallesFilter="detallesFilter"
        :medios_contacto="medios_contacto"
        :campaniasFilter="campaniasFilter"
        :escuela="escuela"
        @save="save"
        @close="close"
        @buscarTelefono="buscarTelefono"
        :contadorVendedoras="contadorVendedoras"
      />
    </v-dialog>


    <AsignacionTwilio
      v-if="dialogTwilio.estatus"
      :dialogTwilio="dialogTwilio"
    />


    <SeguimientoVendedora
      v-if="dialogVendedora.estatus"
      :dialogVendedora="dialogVendedora"
      :contadorVendedoras="contadorVendedoras"
    />

    <DiferenciasVendedora
      v-if="dialogDiferencias.estatus"
      :dialogDiferencias="dialogDiferencias"
      :contadorVendedoras="contadorVendedoras"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import mixinLeads            from '@/mixins/mixinLeads'
  import nuevo_lead            from '@/components/leads/nuevo_lead.vue';
  import basicosTablas         from '@/mixins/basicosTablas'
  import AsignacionTwilio      from '@/components/whatsapp/AsignacionTwilio'
  import SeguimientoVendedora  from '@/components/leads/SeguimientoVendedora'
  import DiferenciasVendedora  from '@/components/leads/DiferenciasVendedora'

  export default {
    components:{
      Alerta,
      carga,
      nuevo_lead,
      AsignacionTwilio,
      SeguimientoVendedora,
      DiferenciasVendedora
    },

    mixins: [ validarErrores, funcionesExcel, mixinLeads, basicosTablas ],
    
    //Data
    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      //datos que se desplegaran en las 2 tablas
      prospectos: [],
      usuarios: [],
      usuario: null,
      dialogUsuario: false,
      dialog: false,

      headers: [
        { text: "Folio"    , value: "folio" },
        { text: "Plantel"  , value: "plantel" },
        { text: "Telefono" , value: "telefono" },
        { text: "Nombre"   , value: "nombre_completo" },
        { text: "Vendedora", value: "name" },
        { text: "Estatus"  , value: "estatus" },
        { text: "Completo" , value: "completo" },
        { text: "Match"    , value: "match" },
        { text: "Fecha"    , value: "fecha_creacion" },
      ],

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      search: "",

      escuela: 1,

      vendedoras: [],

      headers2:[
        { text: "Vendedora"         , value: "vendedora" },
        { text: "% carga laboral"   , value: "carga" },
        { text: "Contactos"         , value: "contactosall" },
        { text: "% convencimiento"  , value: "convencimiento" },
        { text: "Contactos hoy"     , value: "contactos" },
        { text: "ranking"           , value: "ranking" },
        { text: "puedeRecibir"      , value: "puedeRecibir" },
      ],
      search2:'',
      contactosActivos:[],

      contactosVsFecha:[],
      contactosInscritos:[],

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',

      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
      fuentes:[],

      dialogTwilio: {
        estatus: false
      },

      dialogVendedora: {
        estatus: false
      },

      dialogDiferencias: {
        estatus: false
      },

    }),

    //Computed
    computed: {

      ...mapGetters('login',['getdatosUsuario']),


      dialog (val) {
        val || this.close()
      },

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar un LEAD' : 'Editar LEAD'
      },

      filterVendedoras( ){

        return this.vendedoras.filter( el => el.escuela == this.escuela )

      },

      detallesFilter(){
        return this.detalles.filter(el => { return el.idfuentes == this.editedItem.idfuentes })
      },

      campaniasFilter(){
        return this.campanias.filter(el => { return el.idmedio_contacto == this.editedItem.idmedio_contacto })
      },

      contadorVendedoras( ){

        let data = []

        for( const i in this.filterVendedoras ){

          const { nombre, iderp } = this.filterVendedoras[i]

          data.push({
            vendedora: nombre,
            contactos: this.prospectos.filter( el => el.usuario_asignado == iderp).length,
            iderp
          })

        }

        let uniqueArray = data.filter((obj, index, self) => 
          index === self.findIndex((el) => el.iderp === obj.iderp)
        );

        uniqueArray = uniqueArray.sort((a, b) => b.contactos - a.contactos);

        // Carga laboral
        for( const i in uniqueArray ){

          const { iderp } = uniqueArray[i]

          const existenContactos = this.contactosActivos.find( el => el.usuario_asignado == iderp )

          let contactos = existenContactos ? existenContactos.contactos : 0

          let sumaTotal = this.contactosActivos.map(item => item.contactos).reduce((prev, curr) => prev + curr, 0) 

          let carga = (( contactos / sumaTotal ) * 100 ).toFixed( 2 )

          uniqueArray[i]['carga'] = carga

          uniqueArray[i]['contactosall'] = contactos

        }
      

        // Convencimiento
        for( const i in uniqueArray ){

          const { iderp } = uniqueArray[i]

          const existenContactos = this.contactosVsFecha.filter( el => el.usuario_asignado == iderp )

          let contactos = this.contactosVsFecha.filter( el => el.usuario_asignado == iderp ).length

          let inscritos = this.contactosInscritos.filter( el => el.id_usuario_ultimo_cambio == iderp ).length


          let convencimiento = contactos > 0 ? (( inscritos / contactos ) * 100 ).toFixed( 2 ) : 0

          uniqueArray[i]['convencimiento'] = convencimiento

        }

        // Buscar la vendedora que mejor se acomode
        // Ordenar por % de convencimiento (desc) y luego por carga laboral (asc)
        uniqueArray.sort((a, b) => 
          b.convencimiento - a.convencimiento || parseFloat(a.contactos) - parseFloat(b.contactos)
        );

        // Agregar ranking y determinar si pueden recibir más contactos
        uniqueArray.forEach((vendedora, index) => {
          vendedora.puedeRecibir = (vendedora.contactos ) <= 5;
        });

        let contador3 = 0
        uniqueArray.forEach((vendedora, index) => {
          vendedora.ranking = vendedora.puedeRecibir ? contador3 + 1 : 0  ;
          contador3 += vendedora.puedeRecibir ? 1 : 0
        });

        let contador = uniqueArray.filter( el => el.ranking ).length
        uniqueArray.forEach((vendedora, index) => {
          vendedora.ranking = vendedora.ranking ? vendedora.ranking : contador + 1  ;
          contador += vendedora.ranking ? 1 : 0
        });
        

        return uniqueArray

      },

    },

    watch: {
      dialogUsuario(val) {
        val || this.close();
      },
    },

    async created() {
      await this.initialize();
      await this.getFuntes()
      await this.getDetallesFuente()
      await this.getCampanias()
      await this.getVendedoras()
      await this.getCursosEscuela()
      await this.getComoNosConocio()
    },

    //Methods
    methods: {

      //Obtener prospectos y usuarios
      async initialize() {
        this.cargar = true
        await this.getProspectosAllCambiar();
        await this.getVendedoras();

        this.cargar = false
      },

      getProspectosAllCambiar(){

        this.cargar = true
        const payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin,
          escuela : this.escuela
        }

        return this.$http.post("cambiar.prospecto.obtener.prospecto", payload ).then((response) => {
          this.contactosActivos   = response.data.contactosActivos;
          this.contactosVsFecha   = response.data.contactosVsFecha;
          this.contactosInscritos = response.data.contactosInscritos;
          this.prospectos         = response.data.prospectos;
          this.cargar = false

          for( const i in this.prospectos ){

            const { nombre_completo } = this.prospectos[i]

            this.prospectos[i]['completo'] = nombre_completo.match('INCOMPLETO') ? '🧊' : '🔥'   
          
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // getVendedoras() {
      //   this.usuarios = []
      //   return this.$http.get("cambiar.prospecto.obtener.vendedora").then((response) => {
      //     this.usuarios = response.data;
      //   }).catch( error =>{
      //     this.validarError( error.response.data.message )
      //   }).finally( () => { this.cargar = false })
      // },
      
      //Cerrar Dialog Vendedora
      close() {
        this.dialogUsuario = false;
        this.dialog        = false;
      },

      //Modificar este para ver si funciona, esta traido de ActivarProspectos
      actualizar() {
        this.prospecto.usuario_asignado = this.usuario;
        this.cargar = true
        return this.$http.post("cambiar.prospecto.updateprospectovendedora", this.prospecto).then((response) => {
          this.dialogUsuario = false;
          this.cargar = false
          this.prospecto = null;
          this.initialize();
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  };
</script>